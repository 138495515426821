import React from "react"
import { graphql } from "gatsby"

import { PageStructure } from "../ui/atoms"
import { InternalTemplate } from "../ui/templates"

import { getNavLinks } from "../pages/index"

export interface InternalPageProps {
  data: any
}

// markup
const InternalPage: React.FC<InternalPageProps> = (data) => {
  const pageData = data.data.wpPage
  const config = data.data.allWpConfig.edges[0].node.configFields

  return (
    <PageStructure title={pageData.title} description={pageData.title}>
      <InternalTemplate
        links={getNavLinks(data.data)}
        phone={config.phone}
        email={config.email}
        title={pageData.title}
      >
        <div dangerouslySetInnerHTML={{ __html: String(pageData.content) }} />
      </InternalTemplate>
    </PageStructure>
  )
}

export default InternalPage

export const InternalPageQuery = graphql`
  query InternalPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
    }
    headerMenu: wpMenu(id: { eq: "dGVybToyMTk0" }) {
      id
      slug
      menuItems {
        nodes {
          id
          label
          path
        }
      }
    }
    tradeMenu: wpMenu(id: { eq: "dGVybToyMjAw" }) {
      id
      slug
      menuItems {
        nodes {
          id
          label
          path
        }
      }
    }
    allWpConfig {
      edges {
        node {
          configFields {
            phone
            email
          }
        }
      }
    }
  }
`
